import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const limit = 30; // Set the limit to match the backend default

  const videoRefs = useRef({});
  const currentlyPlayingRef = useRef(null);

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    const handlePlay = (event) => {
      const currentVideo = event.target;
      if (currentlyPlayingRef.current && currentlyPlayingRef.current !== currentVideo) {
        currentlyPlayingRef.current.pause();
      }
      currentlyPlayingRef.current = currentVideo;
    };

    Object.values(videoRefs.current).forEach(videoRef => {
      if (videoRef) {
        videoRef.addEventListener('play', handlePlay);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach(videoRef => {
        if (videoRef) {
          videoRef.removeEventListener('play', handlePlay);
        }
      });
    };
  }, [products]);

  const loadProducts = async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`https://www.dailysaleshunt.com/products?limit=${limit}&offset=${offset}`);
      const newProducts = response.data.products;
      setProducts(prevProducts => [...prevProducts, ...newProducts]);
      setOffset(prevOffset => prevOffset + newProducts.length); // Update offset correctly
      setHasMore(response.data.has_more); // Ensure hasMore is set based on response
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatThumbnailPath = (path) => {
    if (!path) return null;
    return `https://www.dailysaleshunt.com/images${path.replace(/\\/g, '/')}`;
  };

  const formatImagePath = (path) => {
    if (!path) return "/static/media/no-image-available.06f0f15cfcb8d681b62c.png";
    return `/images/${path.replace(/^\/+/, '')}`;
  };

  const parseTags = (tagsString) => {
    try {
      return JSON.parse(tagsString);
    } catch {
      return [];
    }
  };

  return (
    <div className="product-list">
      {products.map(product => (
        <div key={product.uid} className="product-item">
          <a 
            href={product.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="product-link"
          >
            <div className="media-container">
              {product.mediaURL && product.mediaURL.toLowerCase().endsWith('.mp4') ? (
                <video 
                  ref={el => { if (el) videoRefs.current[product.uid] = el; }}
                  src={formatImagePath(product.mediaURL)}
                  poster={formatImagePath(product.thumbnail)}
                  controls 
                  preload="metadata"
                  onClick={(e) => e.preventDefault()} // Prevent video from opening link
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img 
                  src={formatImagePath(product.mediaURL)}
                  alt={product.title}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/static/media/no-image-available.06f0f15cfcb8d681b62c.png";
                  }}
                />
              )}
              {product.productCode && <div className="product-code">{product.productCode}</div>}
            </div>
            <h2>{product.title}</h2>
            <p>{product.description}</p>
            <p>Price: ${product.price.toFixed(2)}</p>
            {product.keywords && (
              <div className="tags">
                <strong>Tags:</strong>
                {product.keywords.split(',').map((tag, index) => (
                  <span key={index} className="tag">{tag.trim()}</span>
                ))}
              </div>
            )}
            View Product
          </a>
        </div>
      ))}
      {hasMore && (
        <button onClick={loadProducts} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Load More Products'}
        </button>
      )}
    </div>
  );
}

export default ProductList;
