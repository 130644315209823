import React, { useState, useEffect, useCallback, useRef } from "react";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import axios from "axios";
import { getToken } from './auth';
import './index.css';
import logo from './logo.svg';
import noImageAvailable from './assets/no-image-available.png';
import AdminPage from './components/AdminPage';
import LoginPage from './components/LoginPage';
import ProductList from './components/ProductList';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';


const SearchInput = React.memo(({ value, onChange }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <input
            ref={inputRef}
            type="text"
            placeholder="Search products by № or title"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
});

function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

function App() {
    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState("");
    const [search, setSearch] = useState("");
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);    
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const debouncedSearchRef = useRef(null);
    const [searchHasMore, setSearchHasMore] = useState(true);
    const lastProductRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const api = axios.create({
        baseURL: (process.env.REACT_APP_API_URL || "https://www.dailysaleshunt.com") + "/api",
        headers: {
            "Content-Type": "application/json",
        }
    });

    api.interceptors.request.use(
       (config) => {
           const token = localStorage.getItem('token');
           if (token) {
               config.headers['Authorization'] = `Bearer ${token}`;
           }
           return config;
       },
       (error) => {
           return Promise.reject(error);
       }
    );

    const loadProducts = useCallback(async (tag = "", limit = 30, offset = 0, forceLoad = false) => {
        console.log("loadProducts called with offset:", offset);
        if ((loading || !hasMore) && !forceLoad) {
            console.log("Returning early. Loading:", loading, "HasMore:", hasMore, "ForceLoad:", forceLoad);
            return false;
        }
        setLoading(true);
        try {
            let url = `/products?limit=${limit}&offset=${offset}`;
            if (tag) {
                url += `&tag=${tag}`;
            }
            console.log("Fetching URL:", url);
            const result = await api.get(url);
            console.log("Products Result:", result.data);

            if (!result.data || !Array.isArray(result.data.products)) {
                console.error("Unexpected response structure:", result.data);
                setError("Unexpected response from server");
                return;
            }

            const { products: newProducts, has_more } = result.data;

            setProducts(prevProducts => {
                const updatedProducts = offset === 0 || forceLoad ? newProducts : [...prevProducts, ...newProducts];
                console.log("Updated products count:", updatedProducts.length);
                return updatedProducts;
            });
            setHasMore(has_more);
            setOffset(offset + newProducts.length);
            setError("");
            return true;
        } catch (error) {
            console.error("Failed to load products:", error);
            setError("Failed to load products. Please try again.");
        } finally {
            setLoading(false);
            setIsInitialLoad(false);
        }
    }, [loading, hasMore, api]);

    useEffect(() => {
       if (isInitialLoad) {
          loadProducts("", 30, 0, true);
       }
    }, [isInitialLoad, loadProducts]);

    const loadProductsBySearch = useCallback(async (query, limit = 20, offset = 0) => {
        console.log("loadProductsBySearch called with offset:", offset);
        if (loading) {
            console.log("Returning early. Loading:", loading);
            return;
        }
        setLoading(true);
        try {
            const trimmedQuery = query.trim();
            console.log("Searching for:", trimmedQuery, "Offset:", offset);
            const result = await api.get(`/products/search?q=${encodeURIComponent(trimmedQuery)}&limit=${limit}&offset=${offset}`);
            console.log("Search Results:", result.data);

            const { products: newProducts, has_more, total_count } = result.data;

            setSearchResults(prevResults => {
                const updatedResults = offset === 0 ? newProducts : [...prevResults, ...newProducts];
                console.log("Updated search results count:", updatedResults.length);
                return updatedResults;
            });
            setSearchHasMore(has_more);
            setSearchOffset(prevOffset => prevOffset + newProducts.length);
            setError("");

            console.log("Search has_more:", has_more, "Total count:", total_count);

        } catch (error) {
            console.error("Search failed:", error);
            setError("Search failed. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [loading, api]);

    useEffect(() => {
        debouncedSearchRef.current = debounce((value) => {
            const trimmedValue = value.trim();
            if (trimmedValue === "") {
                setIsSearching(false);
                setSearchResults([]);
                if (selectedTag) {
                    loadProducts(selectedTag, 30, 0, true);
                } else {
                    loadProducts("", 30, 0, true);
                }
            } else {
                setIsSearching(true);
                loadProductsBySearch(trimmedValue);
            }
        }, 300);
    }, [selectedTag, loadProducts, loadProductsBySearch]);

    useEffect(() => {
        if (search === "") {
            setIsSearching(false);
            setSearchResults([]);
            if (selectedTag) {
                loadProducts(selectedTag, 30, 0, true);
            } else {
                loadProducts("", 30, 0, true);
            }
        } else {
            setIsSearching(true);
            loadProductsBySearch(search);
        }
    }, [search, selectedTag]);

    useEffect(() => {
        console.log("Current products:", products);
        console.log("Current offset:", offset);
        console.log("Has more:", hasMore);
    }, [products, offset, hasMore]);

    const handleSearch = useCallback((value) => {
        setSearch(value);
        setSearchOffset(0);
        setSearchHasMore(true);
        if (value.trim() !== "") {
            setIsSearching(true);
            loadProductsBySearch(value, 20, 0);
        } else {
            setSearchResults([]);
            setIsSearching(false);
            loadProducts("", 20, 0, true);
        }
    }, [loadProductsBySearch, loadProducts]);

    const handleTagClick = (tag) => {
        console.log("Tag clicked:", tag);
        setSelectedTag(tag);
        setSearch("");
        setIsSearching(false);
        setSearchResults([]);
        setOffset(0);
        setSearchOffset(0);
        setHasMore(true);
	setIsInitialLoad(true);
        //loadProducts(tag, 30, 0, true);
    };

    const scrollToBottom = useCallback(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    }, []);

    const loadMoreProducts = useCallback(() => {
        console.log("loadMoreProducts called. Loading:", loading, "HasMore:", hasMore, "Offset:", offset);
        if (!loading && hasMore) {
            console.log("Calling loadProducts with offset:", offset);
            loadProducts(selectedTag, 30, offset).then(() => {
                scrollToBottom();
            });
        }
    }, [loading, hasMore, selectedTag, offset, loadProducts, scrollToBottom]);

    const loadMoreSearchResults = useCallback(() => {
        console.log("loadMoreSearchResults called. Loading:", loading, "SearchHasMore:", searchHasMore, "SearchOffset:", searchOffset);
        if (!loading && searchHasMore) {
            console.log("Calling loadProductsBySearch with searchOffset:", searchOffset);
            loadProductsBySearch(search, 20, searchOffset).then(() => {
                scrollToBottom();
            });
        } else {
            console.log("Not loading more results. Loading:", loading, "SearchHasMore:", searchHasMore);
        }
    }, [loading, searchHasMore, search, searchOffset, loadProductsBySearch, scrollToBottom]);

    const loadTags = useCallback(async () => {
        try {
            const result = await api.get('/tags');
            setTags(result.data);
        } catch (error) {
            console.error("Failed to load tags:", error);
            setError("Failed to load tags. Please try again.");
        }
    }, [api]);

    useEffect(() => {
	const fetchInitialData = async () => {
        if (isInitialLoad) {
            try {
                await loadTags();
                const productsLoaded = await loadProducts("", 30, 0, true);
                if (!productsLoaded) {
                    setIsInitialLoad(false); // Prevent further attempts if loading fails
                }
            } catch (error) {
                console.error("Failed to load initial data:", error);
                setError("Failed to load initial data. Please refresh the page.");
                setIsInitialLoad(false);
            } finally {
                setIsLoading(false);
            }
        }
    };

       fetchInitialData();
    }, [isInitialLoad, loadTags, loadProducts]);

    const handleLogin = (token, isAdmin) => {
        localStorage.setItem('token', token);
        localStorage.setItem('isAdmin', isAdmin);
        setIsLoggedIn(true);
        setIsAdmin(isAdmin === 'true' || isAdmin === true);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        setIsLoggedIn(false);
        setIsAdmin(false);
    };

    const ProductList = () => (
        <>
            <div className="search-container">
                <SearchInput value={search} onChange={handleSearch} />
                <p className="search-hint">Search by title, keywords, or product code</p>
            </div>
            <div id="tags">
                {tags.length > 0 ? (
                    tags.map((tag, index) => (
                        <button key={`${tag}-${index}`} onClick={() => handleTagClick(tag)}>
                            {tag}
                        </button>
                    ))
                ) : (
                    <p>No tags available</p>
                )}
            </div>
            <div id="products">
                {((isSearching ? searchResults : products) || []).length > 0 ? (
                    ((isSearching ? searchResults : products) || []).map((product, index) => (
                        <div key={`${product.uid}-${index}`} className="product-card" ref={index === (isSearching ? searchResults : products).length - 1 ? lastProductRef : null}>
                            <a href={product.link} target="_blank" rel="noopener noreferrer">
                                <div className="media-container">
                                    {product.media_url && product.media_url.toLowerCase().endsWith('.mp4') ? (
                                        <div className="video-wrapper">
                                            <video
                                                src={`https://www.dailysaleshunt.com${product.media_url}`}
                                                poster={product.thumbnail ? `https://www.dailysaleshunt.com/images/${product.thumbnail.replace(/\\/g, '/')}` : noImageAvailable}
                                                controls
                                                preload="metadata"
                                            >
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ) : (
                                        <img 
                                            src={product.media_url ? `https://www.dailysaleshunt.com/${product.media_url}` : noImageAvailable} 
                                            alt={product.title}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = noImageAvailable;
                                            }}
                                        />
                                    )}
                                    <div className="product-code">{product.product_code}</div>
                                </div>
                                <h3>{product.title}</h3>
                                <p className="price">
                                    ${typeof product.price === 'number' ? product.price.toFixed(2) : 'N/A'}
                                </p>
                            </a>
                        </div>
                    ))
                ) : (
                    <p className="no-products">No products available</p>
                )}
            </div>
            {isSearching ? (
                searchHasMore && !loading && (
                    <div className="load-more-container">
                        <button onClick={loadMoreSearchResults}>
                            Load More Search Results
                        </button>
                    </div>
                )
            ) : (
                hasMore && !loading && (
                    <div className="load-more-container">
                        <button onClick={loadMoreProducts}>
                            Load More
                        </button>
                    </div>
                )
            )}
        </>
    );

    useEffect(() => {
        console.log("State updated - Products:", products.length, "SearchResults:", searchResults.length, "Offset:", offset, "SearchOffset:", searchOffset, "HasMore:", hasMore);
    }, [products, searchResults, offset, searchOffset, hasMore]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const storedIsAdmin = localStorage.getItem('isAdmin');
        if (token) {
            setIsLoggedIn(true);
            setIsAdmin(storedIsAdmin === 'true');
        }
        setIsLoading(false);
    }, []);

    const Footer = () => (
        <footer className="main-footer">
            <p className="copyright">© {new Date().getFullYear()} Special Offers. All rights reserved.</p>
            <p className="disclaimer">Prices may vary. Please click on the product link for the current, real-time price.</p>
        </footer>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
	return <div className="error-message">{error}</div>;
    }

    return (
        <div className="container">
            <header className="main-header">
                <div className="logo-container">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="logo" />
                    </Link>
                </div>
                <h1 className="site-title">
                    {"Special Offers".split('').map((char, index) => (
                        <span key={index}>{char === ' ' ? '\u00A0' : char}</span>
                    ))}
                </h1>
                <nav className="main-nav">
                    <ul>
                        {isAdmin && <li><Link to="/admin">Admin</Link></li>}
                        {isLoggedIn && (
                            <li><button className="logout-btn" onClick={handleLogout}>Logout</button></li>
                        )}
                    </ul>
                </nav>
            </header>

            <Routes>
                <Route path="/" element={<ProductList />} />
                <Route path="/login" element={
                    isLoggedIn ? <Navigate to="/" replace /> : <LoginPage onLogin={handleLogin} />
                } />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute isAllowed={isAdmin} redirectPath="/">
                            <AdminPage />
                        </ProtectedRoute>
                    }
                />
            </Routes>

            <Footer />
        </div>
    );
}

export default App;
